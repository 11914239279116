<template>
  <nuxt-link
    v-show="isShow"
    class="lottery-button"
    :title="text"
    :to="{ name: toName }"
  >
    <div class="lottery-button-icon">
      <vz-icon
        url="/svg/icon/vz-gift.svg"
        variant="primary"
      />
    </div>
    <div class="lottery-button-text">
      <span>{{ text }}</span>
      <vz-icon
        name="arrow-right"
        size="16"
        class="ml-10 mr-15"
      />
    </div>
  </nuxt-link>
</template>

<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  toName: {
    type: String,
    default: ''
  },
  dateFrom: {
    type: String,
    default: ''
  },
  dateTo: {
    type: String,
    default: ''
  }
})

const config = useRuntimeConfig()

const from = new Date(props.dateFrom);  // -1 because months are from 0 to 11
const to = new Date(props.dateTo);
const check = new Date();

const isShow = computed(() => {
  if (config.public.TEST) {
    return true
  }

  if (!props.dateFrom || !props.dateTo) {
    return true
  }

  return check >= from && check <= to
})

</script>

<style lang="scss" scoped>
$current-color: #FFE500;

.lottery-button {
  width: fit-content;
  display: flex;
  color: $pure-white;
  height: fit-content;
  align-items: center;
  text-transform: uppercase;
  text-decoration: unset;
  gap: 15px;
  border: 2px solid $current-color;
  transition-duration: 0.15s;
  border-radius: 30px;
  box-sizing: content-box;
  font-weight: 700;
  line-height: 14px;

  &-icon {
    background-color: $current-color;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -1px;
  }

  &:hover {
    background-color: $current-color;
    color: $primary;
  }
}
</style>
