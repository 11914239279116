<template>
  <div
    class="vz-dropdown"
    v-on-click-outside="onClickOutside"
    v-scroll="calculateListPosition"
    v-resize="onResize"
  >
    <div 
      class="vz-dropdown-button" 
      @click="onShowList"
      ref="dropdownButtonRef"  
    >
      <slot name="button" />
    </div>
    <div
      v-show="isShowContent"
      class="vz-dropdown-content"
      ref="popupListRef"
      :style="computedStyle"
      @click="onShowList"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
const dropdownButtonRef = ref<HTMLElement|null>(null)
const popupListRef = ref<HTMLElement|null>(null)
const isShowContent = ref(false)
const hasAbsolutParent = ref(false)
const calculatedStyle = ref<any>({})

const computedStyle = computed(() => {
  return hasAbsolutParent.value
    ? calculatedStyle.value
    : {}
})

function onClickOutside(): void {
  if (isShowContent.value) {
    isShowContent.value = false
  }
}

function calculateListPosition(): void {
  if (!popupListRef.value || !hasAbsolutParent.value) {
    return
  }

  const rect = popupListRef.value?.getBoundingClientRect()
  const { top, height, width, left } = dropdownButtonRef.value?.getBoundingClientRect() || { top: 0, height: 0, width: 0, left: 0 }
  if (rect) {
    const fullHeight = top + height + rect.height
    calculatedStyle.value.right = `${left}px`
    if (fullHeight > window.innerHeight) {
      calculatedStyle.value.top = `${top - rect.height}px`
      calculatedStyle.value.boxShadow = '2px -2px 4px rgba(0, 0, 0, 0.15)'
    } else {
      calculatedStyle.value.top = `${top + height}px`
      calculatedStyle.value.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.15)'
    }
  }
}

function onResize(): void {
  let parentElement = dropdownButtonRef.value?.parentElement
  hasAbsolutParent.value = false
  while (parentElement) {
    const { position } = window.getComputedStyle(parentElement, null)
    parentElement = parentElement.parentElement
    if (position === 'absolute') {
      hasAbsolutParent.value = true
      parentElement = null
    }
  }
}

function onShowList(): void {
  isShowContent.value = !isShowContent.value
  calculateListPosition()
}

onMounted(onResize)
</script>
